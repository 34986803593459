import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import useAuth from '../useAuth.js';

import NavigationLink from '../components/navigationLink';
import { Dashboard } from '@mui/icons-material';

const Navigation = () => {
    const { getUserLoginData } = useAuth();
    let [showChildrenLinks, setShowChildrenLinks] = useState();
    const userData = getUserLoginData();
    const analyseDetailsLink = userData.election.analyse_details_link || null;
    let [docsResultsLink, setDocsResultsLink] = useState(null);


    useEffect(() => {
        const regexProcurationURLs = new RegExp('/procurations');
        if (regexProcurationURLs.test(window.location.href)){
            setShowChildrenLinks(true);
        } else {
            setShowChildrenLinks(false);
        }

        if (userData.election.gsheet_link){
            let dashboardOptions = JSON.parse(userData.election.dashboardOptions);
            if (dashboardOptions.resultats === "checked") {
                setDocsResultsLink('https://docs.google.com/spreadsheets/d/' + userData.election.gsheet_link);
            }
        }
        
    }, []);
    


    return(
        <Nav className="main-nav">
            <div className="sidebar-nav">
                <NavigationLink to="#">
                    <i className="nav-icon far fa-map"></i>
                    <span className="nav-text ms-lg-2">Mon territoire</span>
                </NavigationLink>
                <div className="procuration-children-links">
                    <NavigationLink to="/mon-territoire">
                        <span className="nav-icon ms-lg-2">•</span>
                        <span className="nav-text ms-lg-2">Vue d'ensemble</span>
                    </NavigationLink>
                    {analyseDetailsLink &&
                    <NavigationLink to="#" target="_blank" link={analyseDetailsLink}>
                        <span className="nav-icon ms-lg-2">•</span>
                        <span className="nav-text ms-lg-2">Analyse détaillée</span>
                    </NavigationLink>
                    }   
                </div>
                <NavigationLink to="/porte-a-porte">
                    <i className="nav-icon fas fa-door-closed"></i>
                    <span className="nav-text ms-lg-2">Porte à porte / Boitages</span>
                </NavigationLink>
                <NavigationLink to="/plans-actions">
                    <i className="nav-icon fas fa-print"></i>
                    <span className="nav-text ms-lg-2">Mes plans d'actions</span>
                </NavigationLink>
                <NavigationLink to="/resultats">
                    <i className="nav-icon fas fa-chart-line"></i>
                    <span className="nav-text ms-lg-2">Remontée des résultats</span>
                </NavigationLink>
                {docsResultsLink &&
                <div className="procuration-children-links">
                    <NavigationLink to="#" target="_blank" link="https://resultats.eaggle.fr">
                        <span className="nav-icon ms-lg-2">•</span>
                        <span className="nav-text ms-lg-2">Saisie</span>
                    </NavigationLink>
                    <NavigationLink to="#" target="_blank" link={docsResultsLink}>
                        <span className="nav-icon ms-lg-2">•</span>
                        <span className="nav-text ms-lg-2">Documents résultats</span>
                    </NavigationLink>   
                </div>
                }                
                <NavigationLink to="/courriers">
                    <i className="nav-icon far fa-envelope"></i>
                    <span className="nav-text ms-lg-2">Envoi de courriers ciblés</span>
                </NavigationLink>
                <NavigationLink to="/procurations">
                    <i className="nav-icon far fa-file-alt"></i>
                    <span className="nav-text ms-lg-2">Procurations</span>
                </NavigationLink>
                {showChildrenLinks && (false) &&
                <div className="procuration-children-links">
                    <NavigationLink to="/procurations/mandants">
                        <span className="nav-icon ms-lg-4">•</span>
                        <span className="nav-text ms-lg-2">Mandants</span>
                    </NavigationLink>
                    <NavigationLink to="/procurations/mandataires">
                        <span className="nav-icon ms-lg-4">•</span>
                        <span className="nav-text ms-lg-2">Mandataires</span>
                    </NavigationLink>
                    <NavigationLink to="/procurations/parametres">
                        <span className="nav-icon ms-lg-4">•</span>
                        <span className="nav-text ms-lg-2">Paramètres</span>
                    </NavigationLink>
                </div>}
            </div>
        </Nav>
    )
}

export default Navigation;