import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import logoEaggle from '../img/Logo-Eaggle.png'; 
import useAuth from '../useAuth.js';



const Header = () => {
    const { getUserLoginData, logout } = useAuth();
    const navigate = useNavigate();
    let userData = getUserLoginData(); // j'appelle la fonction getUserLoginData pour récupérer les données avant de les insérer dans le header
    let dashboardOptions = JSON.parse(userData.election.dashboardOptions)
    let isAppEnabled = false;
    if (dashboardOptions.appmobile && dashboardOptions.appmobile === "checked") {
        isAppEnabled = true;
    }

    function handleLogout () {
        logout();
        navigate("/login");
    }

    // function showMyAccount (){
    //     navigate("/account");
    // }

    return(
        <header className="main-header">
            <Navbar variant="light" bg="white" className="main-header-navbar navbar navbar-expand topbar static-top shadow-sm justify-content-between">
                <Navbar.Brand href="/">
                    <img className="logoEaggle" src={logoEaggle} alt="logo of Eaggle company" aria-hidden="true" />
                </Navbar.Brand>
                <div className="navbar-menu-wrapper d-flex flex-row">
                    {userData.election.name && <span className="navbar-text border-end d-none d-sm-inline px-3">{userData.election.name}</span>}
                    {userData.election.city && <span className="navbar-text border-end d-none d-sm-inline px-3">{userData.election.city}</span>}
                    {userData.election.code && <span className="navbar-text border-end px-3">
                        <span className="d-none d-lg-inline-block">Code Opération : </span> {userData.election.code}
                        {isAppEnabled && <>
                        <span className="d-none d-lg-inline-block border-start ms-2 ps-2">Code Application : </span> {userData.election.code_dl}
                        </>
                        }
                    </span>}
                    <Dropdown className="dropdown-account ms-2">
                        <Dropdown.Toggle variant="white" className="nav-link dropdown-toggle user-account-link" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                            <span className="me-2 d-none d-lg-inline small">{userData.user}</span>
                            <i className="fas fa-user-circle"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                            {/* <Dropdown.Item className="dropdown-item" onClick={showMyAccount} href="#">Mon compte</Dropdown.Item> */}
                            {userData.election.name && <Dropdown.Item className="dropdown-item d-block d-sm-none">{userData.election.name}</Dropdown.Item>}
                            {userData.election.city && <Dropdown.Item className="dropdown-item d-block d-sm-none">{userData.election.city}</Dropdown.Item>}
                            <Dropdown.Item className="dropdown-item" onClick={handleLogout}>Déconnexion</Dropdown.Item> 
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Navbar>
        </header>
    )
}

export default Header;