import React from 'react';
import ReactDOM from 'react-dom';
// import './css/reset.css';
// import './css/helpers.css';
import App from './App';
import './fonts/Nunito/static/Nunito-Medium.ttf';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
