import './css/App.css';
import './css/layout.css';
import Webpages from './webpages';
import { BrowserRouter } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Webpages />
      </BrowserRouter>
    </div>
  );
}

export default App;
