import {
    Link,
    useMatch,
    useResolvedPath
  } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

const NavigationLink = ({ children, to, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    
    if (props.target && props.target == "_blank"){
        return (
            <div className="px-sm-2 pt-1">
            <a href={props.link} target="_blank" className="px-0 py-3 p-lg-3 sidebar-link-webpages">
                {children}
            </a>
            </div>
        );
    } else {
        return (
            <div className="px-sm-2 pt-1">
            <Link
                to={to}
                {...props}
                className={`px-0 py-3 p-lg-3 sidebar-link-webpages ${(match && to != "#") ? "active" : ""}`}
            >
                {children}
            </Link>
            </div>
        );
    }
}

export default NavigationLink;